import React, { useState, useEffect } from "react";

export function ContactMe() {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(true);
  }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const message = document.getElementById('message').value;
      
        console.log(name, email, message);
      
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ name, email, message }),
        };
      
        try {
          const response = await fetch('https://mail.cochara.com/api/send-email', requestOptions);
          const data = await response.json();
      
          // Log the response data for debugging
          console.log(data);
      
          // Alert the user and clear the form
          alert("Thank you for your message!");
          document.getElementById('name').value = "";
          document.getElementById('email').value = "";
          document.getElementById('message').value = "";
      
        } catch (error) {
          console.error('There was a problem sending the message', error);
        }
      };

    return (
        <>
        <div className={`ease-in-element ${isActive ? 'active' : ''}`}>
        <div className='ContactPage'>
        <div className="ContactDiv">
            <h2 className="ContactMe">Contact Me</h2>
            <p className="ContactP">
                I'd love to hear from you! Whether you have an exciting project in mind or just want to say hello,
                feel free to get in touch using the form below.
            </p>
            <form onSubmit={handleSubmit} className="ContactForm">
                <div className="name-email">
                <input type="text" id="name" className="ContactName" placeholder="Name" required />
                <input type="email" id="email" className="ContactEmail" placeholder="Email"  />
                </div>
                <div className="message">
                <textarea id="message" rows="6" cols="45" className="ContactMessage" placeholder="Message" required></textarea>
                </div>
                <div className="btn">
                <button className="submitBtn" type="submit">Send Message</button>
                </div>
            </form>
            <p className="ContactSignOff">
                You can also reach me via email at <a href="mailto:bryan@cochara.com">Bryan@Cochara.com</a> or connect with me on{' '}
                <a href="https://www.linkedin.com/in/bryancochara/" target="_blank" rel="noopener noreferrer">LinkedIn</a>.
            </p>
        </div>
        </div>
        </div>
        </>
    )
}