import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'nuka-carousel';
import reqSS from './assets/projects/req.png';
import pokeSS from './assets/projects/pokeapp.png';
import LiveSS from './assets/projects/whosthatlive.png';
import DashSS from './assets/projects/dashboard.png';
import TTChatSS from './assets/projects/ttchat.png';
import swipeAnimation from './assets/swipe.json';
import Lottie from 'lottie-react';

export function Projects() {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
      setIsActive(true);
    }, []);

    return (
        <>
        <div className={`ease-in-element ${isActive ? 'active' : ''}`}>
        <div className='ProjectsContainer'>
        <h2 className='ProjectsTitle'>Projects</h2>
          <div className='carousel-div'>
            <Carousel wrapAround className='carousel' renderBottomCenterControls={null}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button className="slider-control-bottomleft" onClick={previousSlide}>
                      PREV
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button className="slider-control-bottomright" onClick={nextSlide}>
                      NEXT
                    </button>
                  )}>
                <div className='carousel'>
                    <h3><a href="https://www.ttchat.pro" target="_blank" rel="noopener noreferrer">Tiktok Chat Helper</a></h3>
                    <img src={TTChatSS} alt="Tiktok Chat Pro" className='ProjectsScreenshot' id='TTChatSS' />
                    <p className='legend'>This tool was built to assist streamers with keeping up with their livestream chat. Currently a Tiktok livestreamer can only see the last 3 or 4 chats before having to scroll, with no option to see a gift or social notification history. This tool can connect to any Tiktok livestream by entering the username of the streamer ("newegg" is typically live during normal business hours if you would like to test it) and will display any chat, gift, or social message, as well as diamonds earned since connecting, and it displays the current total viewers and likes. It features increment buttons to change the displayed font size and a button to toggle between light and dark mode. The site was built to be used on desktops and tablets but it has been configured to work on mobile phones. I built the front end of this app with React and the back end with Node.js, and it is running live on a remote droplet with almost zero downtime.</p>
                </div>
                <div className='carousel'>
                    <h3><a href="https://req.cochara.com" target="_blank" rel="noopener noreferrer">Media Requests</a></h3>
                    <img src={reqSS} alt="Media Requests Screenshot" className='ProjectsScreenshot' />
                    <p className='legend'>This is a basic form submission site that I created for my friends and family to make requests for new media to be added to our media server. This site sends the form data to a custom Node.js API that I created, and is forwarded to a SQL table for me to interact with later.</p>
                </div>
                <div className='carousel'>
                    <h3><a href="https://pokeapp.cochara.com" target="_blank" rel="noopener noreferrer">Who's That Pokemon?!</a></h3>
                    <img src={pokeSS} alt="Who's That Pokemon Screenshot" className='ProjectsScreenshot' />
                    <p className='legend'>This is a web app recreatation of the classic "Who's that Pokemon?!" guessing game. This fetches a random pokemon's name and image from the public PokeAPI, I then take that image and make a new canvas that matches the dimensions of the original with a color overlay. After the user guesses correctly, the image is revealed. A "Reveal Answer" button has been added to assist with demonstration purposes.</p>
                </div>
                <div className='carousel'>
                    <h3><a href='http://whosthatlive.cochara.com' target="_blank" rel="noopener noreferrer">Who's That Pokemon?! Live Streaming Game</a></h3>
                    <img src={LiveSS} alt="Who's That Pokemon Live Tiktok Game Screenshot" className='ProjectsScreenshot' />
                    <p className='legend'>An evolution of my last project, this version connects to the TikTok Live WebSocket to retrieve chat messages and gift donations, as well as automatically retrieves a random Pokemon every 35 seconds. This version also uses Node.js for the back-end and is hosted on a remote droplet. If a user donates a specific gift, they can submit a request for what Pokemon is played next. This version also checks chat messages for user guesses and, if the guess is correct, pushes their username to the leaderboard live. Unfortunately TikTok has tight restrictions on who is allowed to live stream via OBS so this has been converted to an MVP with randomly generated usernames at random intervals, as well as random donation and submission simulation. The chat and donation features have been tested using other livestreams and operate as expected.</p>
                </div>
                <div className='carousel'>
                    <h3><a href="https://dashboard.cochara.com" target="_blank" rel="noopener noreferrer">YouTube Dashboard</a></h3>
                    <img src={DashSS} alt="YouTube Dashboard Screenshot" className='ProjectsScreenshot' id='DashSS' />
                    <p className='legend'>In this project, I utilized the YouTube Data API v3 to retrieve all public information regarding a specific YouTube channel, and then display that information into easily digestible charts in order to gain an overview on how a specific video is doing, as well as the channel overall. The information available in the YouTube Data API is limited, so for this demonstration I use a random number between 1% and 20% and multiply that by the likes in order to simulate the amount of dislikes. Please note I am not affiliated with LinusTechTips in any way, I chose their channel to use as the display model simply because of their history of being public with their stats. I purposefully did not include a search feature for other channels in order to limit the API requests made.</p>
                </div>
                <div className='carousel'>
                    <h3>Additional Projects</h3>
                    <p className='legend'>As mentioned before, I do run and maintain two remotely accessible media servers with custom domains and SSL encryption as well as self hosting all projects listed and this portfolio website using Apache, with firsthand experience using tools such as Caddy and LetsEncrypt. I have been a tech enthusiast all my life and have experienced a wide range of consumer and professional technologies. I appreciate your time and consideration!</p>
                </div>
            </Carousel>
          </div>
            <Lottie className='swipe' animationData={swipeAnimation} />
        </div>
        </div>
        </>
    )
}


{/* <h2 className='ProjectsTitle'>Projects</h2>
<p className='ProjectsP'>Any and all constructive feedback can be submitted with the <Link to="/ContactMe">Contact Me</Link> page and is greatly appreciated!</p>
<h3><a href="https://req.cochara.com" target="_blank" rel="noopener noreferrer">Media Requests</a></h3>
<p className='ProjectsP'>This is a basic form submission site that I created for my friends and family to make requests for new media to be added to our media server. This site sends the form data to a custom Node.js API that I created, and is forwarded to a SQL table for me to interact with later.</p>
<h3><a href="https://pokeapp.cochara.com" target="_blank" rel="noopener noreferrer">Who's That Pokemon?!</a></h3>
<p className='ProjectsP'>This is a web app recreatation of the classic "Who's that Pokemon?!" guessing game. This fetches a random pokemon's name and image from the public PokeAPI, I then take that image and make a new canvas that matches the dimensions of the original with a color overlay. After the user guesses correctly, the image is revealed. A "Reveal Answer" button has been added to assist with demonstration purposes.</p>
<h3><a href='http://whosthatlive.cochara.com' target="_blank" rel="noopener noreferrer">Who's That Pokemon?! Live Streaming Game</a></h3>
<p className='ProjectsP'>An evolution of my last project, this version connects to the TikTok Live WebSocket to retrieve chat messages and gift donations, as well as automatically retrieves a random Pokemon every 35 seconds. This version also uses Node.js for the back-end and is hosted on a remote droplet. If a user donates a specific gift, they can submit a request for what Pokemon is played next. This version also checks chat messages for user guesses and, if the guess is correct, pushes their username to the leaderboard live. Unfortunately TikTok has tight restrictions on who is allowed to live stream via OBS so this has been converted to an MVP with randomly generated usernames at random intervals, as well as random donation and submission simulation. The chat and donation features have been tested using other livestreams and operate as expected.</p>
<h3><a href="https://dashboard.cochara.com" target="_blank" rel="noopener noreferrer">YouTube Dashboard</a></h3>
<p className='ProjectsP'>In this project, I utilized the YouTube Data API v3 to retrieve all public information regarding a specific YouTube channel, and then display that information into easily digestible charts in order to gain an overview on how a specific video is doing, as well as the channel overall. The information available in the YouTube Data API is limited, so for this demonstration I use a random number between 1% and 20% and multiply that by the likes in order to simulate the amount of dislikes. Please note I am not affiliated with LinusTechTips in any way, I chose their channel to use as the display model simply because of their history of being public with their stats. I purposefully did not include a search feature for other channels in order to limit the API requests made.</p>
<h3>Other Projects</h3>
<p className='ProjectsP'>As mentioned before, I do run and maintain two remotely accessible media servers with custom domains and SSL encryption as well as self hosting all projects listed above and this portfolio website using Apache, with firsthand knowledge of tools such as Caddy and LetsEncrypt. I have been a tech enthusiast all my life and have experienced a wide range of consumer and professional technologies. I appreciate your time and consideration!</p> */}