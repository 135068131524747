import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router-dom";
import { Home } from "./pages/home";
import { AboutMe } from "./pages/aboutme";
import { Projects } from "./pages/projects";
import { ContactMe } from "./pages/contactme";
import "./styles.css";
import HomeIcon from "./icons/home.svg";
import AboutMeIcon from "./icons/aboutme.svg";
import ContactIcon from "./icons/contact.svg";
import ProjectsIcon from "./icons/projects.svg";

function App() {

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(true);
  }, []);

  return (
    <div className="App">
    <div className={`ease-in-element ${isActive ? 'active' : ''}`}>
      <header className="App-header">
        <h1 className="navbarTitle">Bryan Cochara</h1>
        <h2 className='Title'>Web Developer</h2>
      <nav className="navbar">
        <ul>
          <li className="NavBarLi"><NavLink to="/"><img src={HomeIcon} alt="Home Icon" width="30" className="icon" /></NavLink></li>
          <li className="NavBarLi"><NavLink to="AboutMe"><img src={AboutMeIcon} alt="About Me Icon" width="30" className="icon" /></NavLink></li>
          <li className="NavBarLi"><NavLink to="Projects"><img src={ProjectsIcon} alt="Projects Icon" width="30" className="icon" id="ProjectsIcon"/></NavLink></li>
          <li className="NavBarLi"><NavLink to="ContactMe"><img src={ContactIcon} alt="Contact Me Icon" width="30" className="icon" id="ContactMeIcon" /></NavLink></li>
        </ul>
      </nav>
      </header>
      <body className="Body">
        <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="AboutMe" element={<AboutMe />} />
          <Route path="Projects" element={<Projects />} />
          <Route path="ContactMe" element={<ContactMe />} />
        </Routes>
        </div>
      </body>
    </div>
    </div>
  );
}

export default App;
