import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import animationData from './assets/transform.json';

export function Home() {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
      setIsActive(true);
    }, []);

    return (
        <>
        <div className={`ease-in-element ${isActive ? 'active' : ''}`}>
            <div className="homeDiv">
                <div className='homeTitleDiv'>
                    <h2 className='HomeTitle'>Transforming ideas <br></br> into <span className='reality'>reality</span></h2>
                    <p className='homeP' id="bottom">I am a web developer from Springfield, Missouri with a passion for building intuitive, creative websites. I am fluent in HTML, CSS, JS, and React. If you are a business seeking a web presence or a potential employer looking to hire, please feel free to contact me <Link to="ContactMe">here</Link>.</p>
                </div>
                <div>
                    <Lottie className='HomeAnimation' animationData={animationData} />
                </div>
            </div>
        </div>
        </>
    )
}