import React, { useEffect, useState } from "react";
import animationData from "./assets/crane.json";
import Lottie from 'lottie-react';
import headshot from './assets/headshot.jpg';

export function AboutMe() {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
      setIsActive(true);
    }, []);

    return (
        <>
        <div className={`ease-in-element ${isActive ? 'active' : ''}`}>
        <div className='aboutMeDiv'>
        <h2 className="aboutMeTitle">Just a small town boy</h2>
        <p className="aboutMe1">
            I spent my childhood around computers thanks to my Dad, moments which laid the foundation for the man I am today. We lived just outside of Springfield in a small town with a population less than 500 people, and I had become the town's IT guy by the time I was in my teens.
            It wasn't until my mid-twenties that I took the plunge and earned my CompTIA A+ certificate, which helped in getting my first real tech job as a contracted system tech for a local print shop, a job which gave me an immense appreciation for the IT field and software development.
        </p>
        <img src={headshot} className="headshot" />
        <Lottie className="crane" animationData={animationData} />
        <p className="aboutMe2">My passion is learning the answer after asking "What if...?". I love being "the guy" and I never say no to any request, anything that I'm not able to do currently is just something that I'm fortunate enough to learn. I love building in React and Node.js, but am flexible enough to adapt to any new framework or language with outstanding pace. Please view my resume below for my professional experience and feel free to contact me with any questions.</p>
        <a href={require("./assets/BCochara Resume.pdf")} className="resume" download="B Cochara Resume">Resumé</a>
        </div>
        </div>
        </>
    )
}